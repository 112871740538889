import React from "react";
import "./Historic.scss";
import { RouteComponentProps } from "react-router";
import { navigationStore } from "../../../stores/NavigationStore";
import {
	CallsClient,
	FollowUpDto,
	StatusCallDto,
} from "../../../Services/elco.API.Swagger";
import { headerStore } from "../../../stores/HeaderStore";
import { AvailableImgs } from "../../../Models/AvailableImgs";
import { footerStore } from "../../../stores/FooterStore";
import { backgroundStore } from "../../../stores/BackgroundStore";
import PageWithTablesTemplate from "../../../Components/PageWithTablesTemplate/PageWithTablesTemplate";
import DropdownFilterCell from "../../../Components/DropdownFilterCell/DropdownFilterCell";

/*interface RowData {
	objectId: string;
	objectQty: number;
	localization: string;
	description: string;
}*/

type props = {
	contract: string;
	site: string;
	object?: string;
};

type state = {
	contractId: string;
	siteId: string;
	objectId?: string;
	contractName: string;
	siteName: string;
	selectedRow: any;
	followUps: FollowUpDto[];
	status: StatusCallDto[];
	callClient: CallsClient;
};

export default class Historic extends React.Component<
	RouteComponentProps<props>,
	state
> {
	constructor(props: RouteComponentProps<props>) {
		super(props);

		this.state = {
			contractId: props.match.params.contract,
			siteId: props.match.params.site,
			objectId: props.match.params.object,
			contractName: "",
			siteName: "",
			selectedRow: null,
			followUps: [],
			status: [],
			callClient: new CallsClient(),
		};

		this.state.callClient
			.getAllFollowUp()
			.then((response) => this.setState({ followUps: response || [] }));
		this.state.callClient
			.getAllStatus()
			.then((response) => this.setState({ status: response || [] }));

		navigationStore.setState({
			contractId: decodeURIComponent(this.state.contractId),
			siteId: decodeURIComponent(this.state.siteId),
			objectId: this.state.objectId
				? decodeURIComponent(this.state.objectId)
				: null,
			callId: null,
			title: this.state.objectId
				? "Historique de l'installation"
				: "Historique du site",
		});

		headerStore.setState({
			leftImgSrc: AvailableImgs.TwentyFourHDepanage,
			rightImgSrc: AvailableImgs.Logo,
			text: "Nous saurons vous convaincre par notre compétence",
		});

		footerStore.setState({
			show: true,
			leftText: null,
			centerText:
				"Prestation par du personnel hautement qualifié: électricien, mecatronicien, mécanicien, soudeur, tuyauteur, monteur-voltigeur",
			rightText: "www.elco.lu",
		});

		backgroundStore.setState({
			img: AvailableImgs.FondVehicule2,
		});
	}

	getFollowUpText(dataItem: any) {
		const item = this.state.followUps.find(
			(f) => f.followup === dataItem.followUp
		);
		if (item) {
			return item.description;
		}
	}

	render() {
		return (
			<PageWithTablesTemplate
				className="historic"
				buttonCenter={{
					isEnableWhenRowSelected: false,
					label: "Retour",
					url: this.state.objectId
						? "/contracts/" +
						  this.state.contractId +
						  "/sites/" +
						  this.state.siteId +
						  "/objects"
						: "/contracts/" + this.state.contractId + "/sites",
				}}
				buttonBottom={{
					isEnableWhenRowSelected: false,
					label: "Accueil",
					url: "/",
				}}
				tables={[
					{
						tableId: `historicTable_${this.state.contractId}_${this.state.siteId}`,
						enableFilters: true,
						promiseData: (options) => {
							return this.state.objectId
								? this.state.callClient.getSiteObjectHistoricCall(
										this.state.contractId,
										this.state.siteId,
										this.state.objectId,
										options
								  )
								: this.state.callClient.getSiteHistoricCall(
										this.state.contractId,
										this.state.siteId,
										options
								  );
						},
						colunsDefinition: [
							{
								title: "Date",
								field: "actionDateTime",
								renderingComponent: (props) => (
									<td>
										{new Date(props.dataItem.actionDateTime).toLocaleDateString(
											navigator.language,
											{
												year: "numeric",
												month: "2-digit",
												day: "2-digit",
											}
										)}
									</td>
								),
							},
							{ title: "Numéro d’appel", field: "callId" },
							{ title: "Sujet", field: "callSubject" },
							{ title: "V/Réf", field: "workOrder" },
							{ title: "Inst. Tech.", field: "objectId" },
							{
								title: "Statut",
								field: "status",
								filterComponent: (props) => (
									<DropdownFilterCell
										items={this.state.status}
										textField="id"
										dataItemKey="id"
										placeholder="(Aucun)"
										{...props}
									/>
								),
								filterable: true,
							},
							{
								title: "Suite à donner",
								field: "followUp",
								filterComponent: (props) => (
									<DropdownFilterCell
										items={this.state.followUps}
										textField="description"
										dataItemKey="description"
										placeholder="(Aucune)"
										{...props}
									/>
								),
								filterable: true,
							},
							{ title: "Motif de suite", field: "followUpReason" },
						],
						onSelectedItemChange: (item) => {
							this.setState({ selectedRow: item });
						},
						dataKey: "objectId",
					},
				]}
			/>
		);
	}
}
