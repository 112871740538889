import React from "react";
import "./Sites.scss";
import { RouteComponentProps } from "react-router";
import { Link } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import { SitesClient } from "../../Services/elco.API.Swagger";
import { navigationStore } from "../../stores/NavigationStore";
import { headerStore } from "../../stores/HeaderStore";
import { AvailableImgs } from "../../Models/AvailableImgs";
import { footerStore } from "../../stores/FooterStore";
import { backgroundStore } from "../../stores/BackgroundStore";
import PageWithTablesTemplate from "../../Components/PageWithTablesTemplate/PageWithTablesTemplate";

type props = {
	contract: string;
};

type state = {
	contractName: string;
	contractId: string;
	selectedRow: any;
};

export default class Sites extends React.Component<
	RouteComponentProps<props>,
	state
> {
	constructor(props: RouteComponentProps<props>) {
		super(props);

		this.state = {
			contractName: "",
			contractId: props.match.params.contract,
			selectedRow: {},
		};

		navigationStore.setState({
			contractId: decodeURIComponent(this.state.contractId),
			siteId: null,
			callId: null,
			objectId: null,
			title: "Liste des sites",
		});

		headerStore.setState({
			leftImgSrc: AvailableImgs.TwentyFourHDepanage,
			rightImgSrc: AvailableImgs.Logo,
			text: "Nous saurons vous convaincre par notre compétence",
		});

		footerStore.setState({
			show: true,
			leftText: null,
			centerText:
				"Conception et réalisation d'installations thermiques, de climatisation, de réfrigération, d'installations sanitaires et d'électricité moyenne tension",
			rightText: null,
		});

		backgroundStore.setState({
			img: AvailableImgs.FondTechniciens,
		});
	}

	render() {
		return (
			<PageWithTablesTemplate
				className="sites"
				buttonCenter={{
					isEnableWhenRowSelected: false,
					label: "Retour contrats",
					url: "/contracts",
				}}
				buttonTop={{
					isEnableWhenRowSelected: true,
					label: "Inst. techniques",
					url:
						"/contracts/" +
						encodeURIComponent(this.state.contractId) +
						"/sites/" +
						encodeURIComponent(
							this.state.selectedRow && this.state.selectedRow.id
						) +
						"/objects",
				}}
				buttonBottom={{
					isEnableWhenRowSelected: false,
					label: "Accueil",
					url: "/",
				}}
				buttonLeftTop={{
					isEnableWhenRowSelected: true,
					label: "Planning des visites",
					url:
						"/contracts/" +
						encodeURIComponent(this.state.contractId) +
						"/sites/" +
						encodeURIComponent(
							this.state.selectedRow && this.state.selectedRow.id
						) +
						"/planning",
				}}
				buttonLeftCenter={{
					isEnableWhenRowSelected: true,
					label: "Rapports préventifs",
					url:
						"/contracts/" +
						encodeURIComponent(this.state.contractId) +
						"/sites/" +
						encodeURIComponent(
							this.state.selectedRow && this.state.selectedRow.id
						) +
						"/prevents",
				}}
				buttonLeftBottom={{
					isEnableWhenRowSelected: true,
					label: "Rapports correctifs",
					url:
						"/contracts/" +
						encodeURIComponent(this.state.contractId) +
						"/sites/" +
						encodeURIComponent(
							this.state.selectedRow && this.state.selectedRow.id
						) +
						"/corrects",
				}}
				tables={[
					{
						tableId: "siteTable_" + this.state.contractId,
						promiseData: (options) =>
							new SitesClient().get(this.state.contractId, options),
						colunsDefinition: [
							{ title: "Site", field: "id" },
							{ title: "Nom du site", field: "name" },
							{
								title: "Historique",
								orderable: false,
								field: "",
								renderingComponent: (props) => (
									<td>
										{props.dataItem.hasHistory && (
											<Link
												href={
													"/contracts/" +
													this.state.contractId +
													"/sites/" +
													encodeURIComponent(props.dataItem.id) +
													"/historic"
												}
											>
												<InputIcon />
											</Link>
										)}
									</td>
								),
							},
						],
						onSelectedItemChange: (item) => {
							this.setState({ selectedRow: item });
						},
						dataKey: "id",
					},
				]}
			/>
		);
	}
}
