// myStore.ts
import { Store } from "react-stores";

export interface INavigationStoreState {
	contractId: string | null;
	siteId: string | null;
	objectId: string | null;
	callId: string | null;
	title: string | null;
}

export const navigationStore = new Store<INavigationStoreState>({
	contractId: null,
	siteId: null,
	objectId: null,
	callId: null,
	title: null,
});
