import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import './Footer.scss';
import { StoreEvent, StoreEventType } from "react-stores";
import { IFooterStoreState, footerStore } from "../../stores/FooterStore";

type props = {
    className: string
}
type state = {
    footerStore: IFooterStoreState | null;
}
export default class Footer extends React.Component<props, state> {
    static defaultProps = {
        className: ''
    }

    constructor(props: props) {
        super(props);

        this.state = {
            footerStore: null
        }
    }

    private storeEvent: StoreEvent<IFooterStoreState> | null = null;

    componentWillUnmount() {
        // Remove store state event binder
        if (this.storeEvent) {
            this.storeEvent.remove();
        }
    }

    componentDidMount() {
        this.storeEvent = footerStore.on(
            StoreEventType.All,
            (storeState: IFooterStoreState, prevState: IFooterStoreState, type: StoreEventType) => {
                this.setState({ footerStore: storeState });
            },
        );
    }

    render() {
        const { rightText, leftText, centerText, show } = this.state.footerStore || { rightText: null, leftText: null, centerText: null, show: false };
        return (
            show && (<AppBar position='relative' className={this.props.className}>
                <Toolbar className="bottom-bar">
                    <div className='left' color='textSecondary'>
                        {leftText}
                    </div>
                    {centerText && <div className='center' color='textSecondary'>
                        {centerText}
                    </div>}
                    <div className='right' color='textSecondary'>
                        {rightText}
                    </div>
                </Toolbar>
            </AppBar>)
        )
    }
}