import React from "react";
import "./PageWithTablesTemplateOnly.scss";
import CustomTables, { TableDefinition } from "../CustomTables/CustomTables";

export interface PageWithTablesTemplateOnlyProps {
	className: string;
	tables?: TableDefinition[];
	id?: string;
}

export interface ButtonProp {
	label: string;
	url: string;
	isEnableWhenRowSelected: boolean;
}

type PageWithTablesTemplateOnlyState = {
	selectedRow: any;
};

export default class PageWithTablesTemplateOnly<
	T extends object
> extends React.Component<
	PageWithTablesTemplateOnlyProps,
	PageWithTablesTemplateOnlyState
> {
	static defaultProps: PageWithTablesTemplateOnlyProps = {
		className: "",
	};
	constructor(props: PageWithTablesTemplateOnlyProps) {
		super(props);

		this.state = {
			selectedRow: [],
		};
	}

	generateKendoTables(tables: TableDefinition[]) {
		let tablesHtml = [];
		for (let i = 0; i < tables.length; i++) {
			const table = tables[i] as TableDefinition;
			tablesHtml.push(
				<CustomTables
					key={table.tableId}
					{...table}
					isOnlyTable={tables.length === 1}
					onSelectedItemChange={(items) => {
						this.setState({ selectedRow: items });
						if (table.onSelectedItemChange) {
							table.onSelectedItemChange(items ? items[0] : null);
						}
					}}
				/>
			);
			if (i < tables.length - 1) {
				tablesHtml.push(<hr key={"hr" + i} />);
			}
		}
		return tablesHtml;
	}

	render() {
		return (
			<div
				className={"tableTemplateOnly" + this.props.className}
				id={this.props.id}
			>
				<div className="tableButtons">
					<div className="filler"></div>
					<div className="table">
						{this.props.tables && this.generateKendoTables(this.props.tables)}
					</div>
				</div>
			</div>
		);
	}
}
