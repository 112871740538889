import React from "react";
import "./Home.scss";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@material-ui/core";
import { Redirect } from "react-router";
import { navigationStore } from "../../stores/NavigationStore";
import { getUserName, isAdmin } from "../../Models/adalConfig";
import { headerStore } from "../../stores/HeaderStore";
import { footerStore } from "../../stores/FooterStore";
import { AvailableImgs } from "../../Models/AvailableImgs";
import { backgroundStore } from "../../stores/BackgroundStore";
import RequestForm from "../Request/Form/RequestForm";

type props = {};
type state = {
	userName: string;
	navigateToContracts: boolean;
	requestDialogOpen: boolean;
	isAdmin: boolean;
};
export default class Home extends React.Component<props, state> {
	constructor(props: props) {
		super(props);

		this.state = {
			userName: getUserName(),
			navigateToContracts: false,
			requestDialogOpen: false,
			isAdmin: isAdmin(),
		};

		this.handleClickNext = this.handleClickNext.bind(this);

		navigationStore.setState({
			contractId: null,
			siteId: null,
			callId: null,
			objectId: null,
			title: null,
		});

		headerStore.setState({
			leftImgSrc: AvailableImgs.TwentyFourHDepanage,
			rightImgSrc: AvailableImgs.Logo,
			text: "Nous saurons vous convaincre par notre compétence",
		});

		footerStore.setState({
			show: false,
		});

		backgroundStore.setState({
			img: AvailableImgs.FondVehicule,
		});
	}

	handleClickNext() {
		this.setState({ navigateToContracts: true });
	}

	private handelOpenDialog = (): void => {
		this.setState({
			requestDialogOpen: true,
		});
	};
	private handleCloseDialog = () => {
		this.setState({
			requestDialogOpen: false,
		});
	};

	render() {
		return this.state.navigateToContracts ? (
			<Redirect to="contracts" />
		) : (
			<div className="home">
				<div className="welcome">Bienvenue sur le portail client Elco</div>
				<div className="image"></div>
				<div className="bottom-content">
					<div className="left-message">
						Conseil, installations et maintenance <br />
						En technologie et énergie pour tout <br />
						type de bâtiment
					</div>
					<div className="right-message">
						<div className="activity">Maintenance et dépannages</div>
						<div className="availability">7/7 - 24/24</div>
						<div>&nbsp;</div>
					</div>
					<div className="buttons">
						{/* <Button variant="contained" disabled>Génerer Appel</Button> */}
						<Button variant="contained" onClick={() => this.handelOpenDialog()}>
							Générer un appel
						</Button>
						<Button variant="contained" onClick={() => this.handleClickNext()}>
							Liste des contrats
						</Button>
						<div className="emptyBlock"></div>
					</div>
				</div>
				<Dialog
					onClose={this.handleCloseDialog.bind(this)}
					aria-labelledby="customized-dialog-title"
					open={this.state.requestDialogOpen}
					fullWidth={true}
					maxWidth="sm"
				>
					<DialogTitle id="customized-dialog-title">
						Générer un appel
					</DialogTitle>
					<DialogContent dividers>
						<RequestForm onRequestClose={this.handleCloseDialog.bind(this)} />
					</DialogContent>
					<DialogActions>
						<Button
							autoFocus
							onClick={this.handleCloseDialog.bind(this)}
							color="primary"
						>
							Fermer
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
