import React from "react";
import "./ContractsReports.scss";
import { Link } from "@material-ui/core";

import {
	DocumentClient,
	DocumentTypeDto,
} from "../../../Services/elco.API.Swagger";
import { showFile } from "../../../Models/ShowFile";
import GetAppIcon from "@material-ui/icons/GetApp";
import { AvailableImgs } from "../../../Models/AvailableImgs";
import { backgroundStore } from "../../../stores/BackgroundStore";
import _ from "lodash";
import { LoadingPanel } from "../../../Components/LoadingPanel/LoadingPanel";
import PageWithTablesTemplateOnly from "../../../Components/PageWithTableTemplateOnly/PageWithTablesTemplateOnly";
import DropdownFilterCell from "../../../Components/DropdownFilterCell/DropdownFilterCell";
import { ContractDocumentType } from "./DocumentType";

export interface ContractsReportsProps {
	contract: string;
	docType: ContractDocumentType;
}

type state = {
	contractId: string;
	contractName: string;
	siteName: string;
	selectedRowReports: any;
	reports: any[];
	loading: boolean;
	documentTypes: DocumentTypeDto[];
	documentClient: DocumentClient;
};

export default class ContractsReports extends React.Component<
	ContractsReportsProps,
	state
> {
	constructor(props: ContractsReportsProps) {
		super(props);

		this.state = {
			contractId: props.contract,
			contractName: "",
			siteName: "",
			selectedRowReports: null,
			reports: [],
			loading: false,
			documentTypes: [],
			documentClient: new DocumentClient(),
		};

		this.state.documentClient
			.getAllTypes()
			.then((response) => this.setState({ documentTypes: response || [] }));

		backgroundStore.setState({
			img: AvailableImgs.FondToitVentilation,
		});
	}

	handleDownloadDocument = (documentId: number) => {
		this.setState({ loading: true });
		if (this.props.docType === ContractDocumentType.report) {
			new DocumentClient()
				.downloadContractDocument(documentId)
				.then((response) => (response ? showFile(response) : null))
				.finally(() => this.setState({ loading: false }));
		} else {
			new DocumentClient()
				.downloadContractInvoice(documentId)
				.then((response) => (response ? showFile(response) : null))
				.finally(() => this.setState({ loading: false }));
		}
	};

	render() {
		const idPage = _.uniqueId("contract_documents_");
		const isReport = this.props.docType === ContractDocumentType.report;
		return (
			<div id={idPage} style={{ width: "100%" }}>
				{this.state.loading && <LoadingPanel id={idPage} />}
				{isReport ? (
					<PageWithTablesTemplateOnly
						className="contracts-reports"
						tables={[
							{
								tableId: "contractDocumentsTable",
								enableFilters: true,
								promiseData: (options) =>
									new DocumentClient().contractDocuments(
										this.state.contractId,
										options
									),
								colunsDefinition: [
									{
										title: "Date",
										field: "createddatetime",
										renderingComponent: (props) => (
											<td>
												{new Date(
													props.dataItem.createddatetime
												).toLocaleDateString(navigator.language, {
													year: "numeric",
													month: "2-digit",
													day: "2-digit",
												})}
											</td>
										),
									},
									{
										title: "Type de document",
										field: "typeName",
										filterable: true,
										filterComponent: (props) => (
											<DropdownFilterCell
												items={this.state.documentTypes}
												textField="name"
												dataItemKey="name"
												placeholder="(Aucun)"
												{...props}
											/>
										),
									},
									{ title: "Nom du document", field: "name" },
									{
										title: "Télécharger",
										field: "recid",
										orderable: false,
										renderingComponent: (props) => (
											<td>
												{props.dataItem && (
													<Link
														href="#"
														onClick={() =>
															this.handleDownloadDocument(props.dataItem.recid)
														}
													>
														<GetAppIcon />
													</Link>
												)}
											</td>
										),
									},
								],
								onSelectedItemChange: (item) => {
									this.setState({ selectedRowReports: item });
								},
							},
						]}
					/>
				) : (
					<PageWithTablesTemplateOnly
						className="contracts-reports"
						tables={[
							{
								tableId: "contractInvoicesTable",
								promiseData: (options) =>
									new DocumentClient().contractInvoices(
										this.state.contractId,
										options
									),
								colunsDefinition: [
									{
										title: "Date de facture",
										field: "invoiceDate",
										renderingComponent: (props) => (
											<td>
												{new Date(
													props.dataItem.invoiceDate
												).toLocaleDateString(navigator.language, {
													year: "numeric",
													month: "2-digit",
													day: "2-digit",
												})}
											</td>
										),
									},
									{
										title: "N° de facture",
										field: "name",
										orderable: true,
									},
									{
										title: "Télécharger",
										field: "recid",
										orderable: false,
										renderingComponent: (props) => (
											<td>
												{props.dataItem && (
													<Link
														href="#"
														onClick={() =>
															this.handleDownloadDocument(props.dataItem.recid)
														}
													>
														<GetAppIcon />
													</Link>
												)}
											</td>
										),
									},
								],
								onSelectedItemChange: (item) => {
									this.setState({ selectedRowReports: item });
								},
							},
						]}
					/>
				)}
			</div>
		);
	}
}
