import { FileResponse } from "../Services/elco.API.Swagger";

export function showFile(response: FileResponse | null) {
  if (!response) return;
  const contentDisposition = response.headers ? response.headers["content-disposition"] : undefined;
  const fileNameMatch = contentDisposition ? /filename\*=UTF-8''"?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
  const fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
  const decodedFileName = fileName ? decodeURI(fileName || '') : (response.fileName || '')

  // It is necessary to create a new blob object with mime-type
  // explicitly set otherwise only Chrome works like it should
  let newBlob = new Blob([response.data], { type: 'application/octect-stream' });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, decodedFileName);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  let data = window.URL.createObjectURL(newBlob);
  let link = document.createElement('a');
  link.href = data;
  link.download = decodedFileName || '';
  link.click();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}