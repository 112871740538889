import React from "react";
import "./App.scss";
import { getToken } from "./Models/adalConfig";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Pages/Home/Home";
import { CssBaseline } from "@material-ui/core";
import Contracts from "./Pages/Contracts/Contracts";
import Sites from "./Pages/Sites/Sites";
import Prevents from "./Pages/Reports/Prevents/Prevents";
import Correctifs from "./Pages/Reports/Correctifs/Correctifs";
import Objects from "./Pages/Reports/Objects/Objects";
import TopBar from "./Components/TopBar/TopBar";
import ContractsReports from "./Pages/Contracts/Documents/ContractsReports";
import NavigationHeader from "./Components/NavigationHeader/NavigationHeader";
import TelemetryProvider from "./Components/AppInsights/TelemetryProvider";
import Footer from "./Components/Footer/Footer";
import {
	IBackgroundStoreState,
	backgroundStore,
} from "./stores/BackgroundStore";
import { StoreEvent, StoreEventType } from "react-stores";
import { AvailableImgs } from "./Models/AvailableImgs";
import Planning from "./Pages/Reports/Planning/Planning";
import Historic from "./Pages/Reports/Historic/Historic";
import Request from "./Pages/Request/Request";

axios.defaults.headers.common.Authorization = "Bearer " + getToken();

type props = {};
type state = {
	backgroundStore: IBackgroundStoreState | null;
};
class App extends React.Component<props, state> {
	private storeEvent: StoreEvent<IBackgroundStoreState> | null = null;

	constructor(props: props) {
		super(props);

		this.state = {
			backgroundStore: null,
		};
	}

	componentWillUnmount() {
		// Remove store state event binder
		if (this.storeEvent) {
			this.storeEvent.remove();
		}
	}

	componentDidMount() {
		this.storeEvent = backgroundStore.on(
			StoreEventType.All,
			(
				storeState: IBackgroundStoreState,
				prevState: IBackgroundStoreState,
				type: StoreEventType
			) => {
				this.setState({ backgroundStore: storeState });
			}
		);
	}
	render() {
		const { img } = this.state.backgroundStore || {
			img: AvailableImgs.FondVehicule,
		};
		return (
			<div className={"main " + img}>
				<TopBar></TopBar>
				<NavigationHeader className="navigation" />
				<div className="content">
					<Router>
						<TelemetryProvider
							instrumentationKey={
								process.env.REACT_APP_APP_INSIGHT_INSTRUMENTATION_KEY!
							}
							after={() => {}}
						>
							<CssBaseline />
							<Switch>
								<Route
									path="/contracts/:contract/sites/:site/objects"
									exact
									component={Objects}
								/>
								<Route
									path="/contracts/:contract/sites/:site/corrects"
									exact
									component={Correctifs}
								/>
								<Route
									path="/contracts/:contract/sites/:site/prevents"
									exact
									component={Prevents}
								/>
								<Route
									path="/contracts/:contract/sites/:site/planning"
									exact
									component={Planning}
								/>
								<Route
									path="/contracts/:contract/sites/:site/objects/:object/historic"
									exact
									component={Historic}
								/>
								<Route
									path="/contracts/:contract/sites/:site/historic"
									exact
									component={Historic}
								/>
								<Route
									path="/contracts/:contract/sites"
									exact
									component={Sites}
								/>
								<Route
									path="/contracts/:contract/documents"
									exact
									component={ContractsReports}
								/>
								<Route path="/contracts" exact component={Contracts} />
								<Route path="/request" exact component={Request} />
								<Route path="/" component={Home} />
							</Switch>
						</TelemetryProvider>
					</Router>
				</div>
				<Footer />
			</div>
		);
	}
}
export default App;
