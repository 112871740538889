import React from "react";
import "./Objects.scss";
import { RouteComponentProps } from "react-router";
import { Link } from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import { navigationStore } from "../../../stores/NavigationStore";
import { ObjectsClient } from "../../../Services/elco.API.Swagger";
import { headerStore } from "../../../stores/HeaderStore";
import { AvailableImgs } from "../../../Models/AvailableImgs";
import { footerStore } from "../../../stores/FooterStore";
import { backgroundStore } from "../../../stores/BackgroundStore";
import PageWithTablesTemplate from "../../../Components/PageWithTablesTemplate/PageWithTablesTemplate";

interface RowData {
	objectId: string;
	objectQty: number;
	localization: string;
	description: string;
}

type props = {
	contract: string;
	site: string;
};

type state = {
	contractId: string;
	siteId: string;
	contractName: string;
	siteName: string;
	selectedRow: any;
};

export default class Objects extends React.Component<
	RouteComponentProps<props>,
	state
> {
	constructor(props: RouteComponentProps<props>) {
		super(props);

		this.state = {
			contractId: props.match.params.contract,
			siteId: props.match.params.site,
			contractName: "",
			siteName: "",
			selectedRow: null,
		};

		navigationStore.setState({
			contractId: decodeURIComponent(this.state.contractId),
			siteId: decodeURIComponent(this.state.siteId),
			callId: null,
			objectId: null,
			title: "Liste des installations techniques",
		});

		headerStore.setState({
			leftImgSrc: AvailableImgs.TwentyFourHDepanage,
			rightImgSrc: AvailableImgs.Logo,
			text: "Nous saurons vous convaincre par notre compétence",
		});

		footerStore.setState({
			show: true,
			leftText: null,
			centerText:
				"Prestation par du personnel hautement qualifié: électricien, mecatronicien, mécanicien, soudeur, tuyauteur, monteur-voltigeur",
			rightText: "www.elco.lu",
		});

		backgroundStore.setState({
			img: AvailableImgs.FondVehicule2,
		});
	}

	render() {
		return (
			<PageWithTablesTemplate
				className="objects"
				buttonCenter={{
					isEnableWhenRowSelected: false,
					label: "Retour",
					url: "/contracts/" + this.state.contractId + "/sites",
				}}
				buttonBottom={{
					isEnableWhenRowSelected: false,
					label: "Accueil",
					url: "/",
				}}
				tables={[
					{
						tableId: `objectTable_${this.state.contractId}_${this.state.siteId}`,
						promiseData: (options) =>
							new ObjectsClient().getObjects(
								this.state.contractId,
								this.state.siteId,
								options
							),
						colunsDefinition: [
							{ title: "Inst. tech.", field: "objectId" },
							{ title: "Nombre", field: "objectQty" },
							{ title: "Description", field: "description" },
							{ title: "Localisation", field: "localization" },
							{
								title: "Historique",
								orderable: false,
								field: "",
								renderingComponent: (props) => (
									<td>
										{props.dataItem.hasHistory && (
											<Link
												href={
													"/contracts/" +
													this.state.contractId +
													"/sites/" +
													encodeURIComponent(this.state.siteId) +
													"/objects/" +
													encodeURIComponent(props.dataItem.objectId) +
													"/historic"
												}
											>
												<InputIcon />
											</Link>
										)}
									</td>
								),
							},
						],
						onSelectedItemChange: (item) => {
							this.setState({ selectedRow: item });
						},
						dataKey: "objectId",
					},
				]}
			/>
		);
	}
}
