import { AuthenticationContext, AdalConfig, adalFetch } from 'react-adal';
import _ from 'lodash';

const graphApiUrl = 'https://graph.microsoft.com';

const adalConfig: AdalConfig = {
  tenant: process.env.REACT_APP_TENANT,
  clientId: process.env.REACT_APP_CLIENT_ID!,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  endpoints: {
    api: graphApiUrl,
  },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);

export const getTokenData = () => {
  const token = getToken();
  return token ? JSON.parse(atob(token.split('.')[1])) : {};
};

export const isAdmin = () => {
  const tokenData = getTokenData();
  const roles = _.get(tokenData, 'roles');
  return roles && _.includes(roles, process.env.REACT_APP_ADMIN_ROLE_NAME);
};

export const getUserName = () => {
  const tokenData = getTokenData();
  return tokenData.email || tokenData.upn;
};

export const getGivenName = () => {
  const tokenData = getTokenData();
  return tokenData.name;
};

export const adalApiFetch = (fetch: (input: string, init: any) => Promise<any>, url: string, options: any) => {
  const userName = getUserName();
  if (userName) {
    authContext.config.extraQueryParameter = 'login_hint=' + userName;
  }

  return adalFetch(authContext, graphApiUrl, fetch, url, options).then(result => result.json());
};

export const adalGraphFetch = (suffix: string) =>
  adalApiFetch(fetch, graphApiUrl + '/v1.0/' + suffix, {}).catch(() => ({}));
