import React from "react";
import "./NavigationHeader.scss";
import {
	ContractClient,
	SitesClient,
	ObjectsClient,
} from "../../Services/elco.API.Swagger";
import { StoreEvent, StoreEventType } from "react-stores";
import {
	INavigationStoreState,
	navigationStore,
} from "../../stores/NavigationStore";
import { getUserName, authContext } from "../../Models/adalConfig";
import { Link } from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";

type props = {
	className: string;
};
type state = {
	navigationStore: INavigationStoreState | null;
	contractId: string | null;
	siteId: string | null;
	callId: string | null;
	objectId: string | null;
	feature: string | null;
	contractName: string | null;
	siteName: string | null;
	callName: string | null;
	objectName: string | null;
	userName: string;
	title: string | null;
};

export default class NavigationHeader extends React.Component<props, state> {
	private storeEvent: StoreEvent<INavigationStoreState> | null = null;

	componentWillUnmount() {
		// Remove store state event binder
		if (this.storeEvent) {
			this.storeEvent.remove();
		}
	}

	componentDidMount() {
		this.storeEvent = navigationStore.on(
			StoreEventType.All,
			(
				storeState: INavigationStoreState,
				prevState: INavigationStoreState,
				type: StoreEventType
			) => {
				this.updateContractName(storeState);
				this.updateSiteName(storeState);
				this.updateObjectName(storeState);
				this.setState({
					navigationStore: storeState,
					callId: storeState.callId,
					callName: storeState.callId,
					title: storeState.title,
				});
			}
		);
	}

	static defaultProps = {
		className: "",
	};

	constructor(props: props) {
		super(props);

		this.state = {
			navigationStore: null,
			contractId: null,
			siteId: null,
			callId: null,
			objectId: null,
			feature: null,
			contractName: null,
			siteName: null,
			callName: null,
			objectName: null,
			title: null,
			userName: getUserName(),
		};

		this.updateContractName = this.updateContractName.bind(this);
		this.updateSiteName = this.updateSiteName.bind(this);
		this.updateObjectName = this.updateObjectName.bind(this);
	}

	private updateContractName(storeState: INavigationStoreState) {
		if (storeState) {
			if (storeState.contractId) {
				if (storeState.contractId !== this.state.contractId) {
					new ContractClient()
						.getById(encodeURIComponent(storeState.contractId))
						.then((response) => {
							if (response && response.name && response.id) {
								this.setState({ contractId: response.id });
								this.setState({ contractName: response.name });
							} else {
								this.setState({ contractName: null });
							}
						});
				}
			} else {
				this.setState({ contractId: null });
				this.setState({ contractName: null });
			}
		} else {
			this.setState({ contractId: null });
			this.setState({ contractName: null });
		}
	}

	private updateSiteName(storeState: INavigationStoreState) {
		if (storeState) {
			if (storeState.siteId) {
				if (storeState.siteId !== this.state.siteId && storeState.contractId) {
					new SitesClient()
						.getById(
							encodeURIComponent(storeState.contractId),
							encodeURIComponent(storeState.siteId)
						)
						.then((response) => {
							if (response && response.name && response.id) {
								this.setState({ siteId: response.id });
								this.setState({ siteName: response.name });
							} else {
								this.setState({ siteName: null });
							}
						});
				}
			} else {
				this.setState({ siteId: null });
				this.setState({ siteName: null });
			}
		} else {
			this.setState({ siteId: null });
			this.setState({ siteName: null });
		}
	}

	private updateObjectName(storeState: INavigationStoreState) {
		if (storeState) {
			if (storeState.objectId) {
				if (
					storeState.objectId !== this.state.objectId &&
					storeState.contractId &&
					storeState.siteId
				) {
					new ObjectsClient()
						.getById(
							encodeURIComponent(storeState.contractId),
							encodeURIComponent(storeState.siteId),
							encodeURIComponent(storeState.objectId)
						)
						.then((response) => {
							if (response && response.description && response.objectId) {
								this.setState({ objectId: response.objectId });
								this.setState({ objectName: response.description });
							} else {
								this.setState({ objectName: null });
							}
						});
				}
			} else {
				this.setState({ objectId: null });
				this.setState({ objectName: null });
			}
		} else {
			this.setState({ objectId: null });
			this.setState({ objectName: null });
		}
	}

	logout() {
		authContext.logOut();
	}

	render() {
		return (
			<div className={"navigationHeader " + this.props.className}>
				<div className="navigation">
					{this.state.contractId && (
						<div className="row">
							<div className="label">Contrat</div>
							<div className="value">:&nbsp;{this.state.contractId}</div>
						</div>
					)}
					{this.state.contractName && (
						<div className="row">
							<div className="label">Nom</div>
							<div className="value">:&nbsp;{this.state.contractName}</div>
						</div>
					)}
					{this.state.siteName && (
						<div className="row">
							<div className="label">Site</div>
							<div className="value">:&nbsp;{this.state.siteName}</div>
						</div>
					)}
					{this.state.objectName && (
						<div className="row">
							<div className="label">Installation</div>
							<div className="value">:&nbsp;{this.state.objectName}</div>
						</div>
					)}
					{this.state.callName && (
						<div className="row">
							<div className="label">Appel</div>
							<div className="value">:&nbsp;{this.state.callName}</div>
						</div>
					)}
				</div>
				<div className="title">{this.state.title}</div>
				<div className="login">
					<div className="login-text">
						<PermIdentityIcon />
						{this.state.userName}
					</div>
					<Link className="login-logout" onClick={() => this.logout()}>
						<PowerSettingsNewIcon />
						&nbsp;Se déconnecter
					</Link>
				</div>
			</div>
		);
	}
}
