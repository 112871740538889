import React from "react";
import "./PageWithTablesTemplate.scss";
import { Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import CustomTables, { TableDefinition } from "../CustomTables/CustomTables";

export interface PageWithTablesTemplateProps {
	buttonTop: ButtonProp | null;
	buttonCenter: ButtonProp | null;
	buttonBottom: ButtonProp | null;
	buttonLeftTop: ButtonProp | null;
	buttonLeftCenter: ButtonProp | null;
	buttonLeftBottom: ButtonProp | null;
	className: string;
	tables?: TableDefinition[];
	id?: string;
}

export interface ButtonProp {
	label: string;
	url: string;
	isEnableWhenRowSelected: boolean;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

type PageWithTablesTemplateState = {
	selectedRow: any;
};

export default class PageWithTablesTemplate<
	T extends object
> extends React.Component<
	PageWithTablesTemplateProps,
	PageWithTablesTemplateState
> {
	static defaultProps: PageWithTablesTemplateProps = {
		buttonBottom: null,
		buttonCenter: null,
		buttonTop: null,
		buttonLeftBottom: null,
		buttonLeftCenter: null,
		buttonLeftTop: null,
		className: "",
	};
	constructor(props: PageWithTablesTemplateProps) {
		super(props);

		this.state = {
			selectedRow: [],
		};
	}

	generateKendoTables(tables: TableDefinition[]) {
		let tablesHtml = [];
		for (let i = 0; i < tables.length; i++) {
			const table = tables[i] as TableDefinition;
			tablesHtml.push(
				<CustomTables
					key={table.tableId}
					{...table}
					isOnlyTable={tables.length === 1}
					onSelectedItemChange={(items) => {
						this.setState({ selectedRow: items });
						if (table.onSelectedItemChange) {
							table.onSelectedItemChange(items ? items[0] : null);
						}
					}}
				/>
			);
			if (i < tables.length - 1) {
				tablesHtml.push(<hr key={"hr" + i} />);
			}
		}
		return tablesHtml;
	}

	render() {
		return (
			<div
				className={"tableTemplate " + this.props.className}
				id={this.props.id}
			>
				<div className="tableButtons">
					<div className="filler"></div>
					<div className="table">
						{this.props.tables && this.generateKendoTables(this.props.tables)}
					</div>
					<div className="buttons">
						{this.props.buttonTop ? (
							<Button
								variant="contained"
								className="button-sites"
								disabled={
									this.props.buttonTop.isEnableWhenRowSelected &&
									(!this.state.selectedRow ||
										this.state.selectedRow.length === 0)
								}
								component={RouterLink}
								to={this.props.buttonTop.url!}
								onClick={this.props.buttonTop.onClick}
							>
								{this.props.buttonTop.label}
							</Button>
						) : (
							<div className="emptyBlock"></div>
						)}
						{this.props.buttonCenter ? (
							<Button
								variant="contained"
								className="button-sites"
								disabled={
									this.props.buttonCenter.isEnableWhenRowSelected &&
									(!this.state.selectedRow ||
										this.state.selectedRow.length === 0)
								}
								component={RouterLink}
								to={this.props.buttonCenter.url!}
								onClick={this.props.buttonCenter.onClick}
							>
								{this.props.buttonCenter.label}
							</Button>
						) : (
							<div className="emptyBlock"></div>
						)}
						{this.props.buttonBottom ? (
							<Button
								variant="contained"
								className="button-sites"
								disabled={
									this.props.buttonBottom.isEnableWhenRowSelected &&
									(!this.state.selectedRow ||
										this.state.selectedRow.length === 0)
								}
								component={RouterLink}
								to={this.props.buttonBottom.url!}
								onClick={this.props.buttonBottom.onClick}
							>
								{this.props.buttonBottom.label}
							</Button>
						) : (
							<div className="emptyBlock"></div>
						)}
					</div>
					<div className="buttonsRight">
						{this.props.buttonLeftTop ? (
							<Button
								variant="contained"
								className="button-sites"
								disabled={
									this.props.buttonLeftTop.isEnableWhenRowSelected &&
									(!this.state.selectedRow ||
										this.state.selectedRow.length === 0)
								}
								component={RouterLink}
								to={this.props.buttonLeftTop.url!}
								onClick={this.props.buttonLeftTop.onClick}
							>
								{this.props.buttonLeftTop.label}
							</Button>
						) : (
							<div className="emptyBlock"></div>
						)}
						{this.props.buttonLeftCenter ? (
							<Button
								variant="contained"
								className="button-sites"
								disabled={
									this.props.buttonLeftCenter.isEnableWhenRowSelected &&
									(!this.state.selectedRow ||
										this.state.selectedRow.length === 0)
								}
								component={RouterLink}
								to={this.props.buttonLeftCenter.url!}
								onClick={this.props.buttonLeftCenter.onClick}
							>
								{this.props.buttonLeftCenter.label}
							</Button>
						) : (
							<div className="emptyBlock"></div>
						)}
						{this.props.buttonLeftBottom ? (
							<Button
								variant="contained"
								className="button-sites"
								disabled={
									this.props.buttonLeftBottom.isEnableWhenRowSelected &&
									(!this.state.selectedRow ||
										this.state.selectedRow.length === 0)
								}
								component={RouterLink}
								to={this.props.buttonLeftBottom.url!}
								onClick={this.props.buttonLeftBottom.onClick}
							>
								{this.props.buttonLeftBottom.label}
							</Button>
						) : (
							<div className="emptyBlock"></div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
