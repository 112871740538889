import 'core-js/stable'
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { runWithAdal } from 'react-adal';
import { authContext } from './Models/adalConfig';
import ReactDOM from 'react-dom';
import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';

const DO_NOT_LOGIN = false;

runWithAdal(
    authContext,
    function () { return ReactDOM.render(<App />, document.getElementById('root')) },
    DO_NOT_LOGIN
);

serviceWorker.unregister();