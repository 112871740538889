// myStore.ts
import { Store } from 'react-stores';
import { AvailableImgs } from '../Models/AvailableImgs';

export interface IBackgroundStoreState {
  img: AvailableImgs,
}
 
export const backgroundStore = new Store<IBackgroundStoreState>({
  img: AvailableImgs.FondVehicule,
});