import React from "react";

import {
	Grid,
	GridColumn as Column,
	GridSelectionChangeEvent,
	GridRowClickEvent,
	GridPageChangeEvent,
	GridSortChangeEvent,
	GridCellProps,
	GridFilterCellProps,
	GridFilterChangeEvent,
	GridPagerSettings,
	GridColumnResizeEvent,
} from "@progress/kendo-react-grid";

import "@progress/kendo-theme-default/dist/all.css";
import { TextField } from "@material-ui/core";
import "./CustomTables.scss";
import { IQueryOptions, QueryOptions } from "../../Services/elco.API.Swagger";
import {
	SortDescriptor,
	isCompositeFilterDescriptor,
	CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import { operatorIn } from "../DropdownFilterCell/DropdownFilterCell";
import _ from "lodash";
import { LoadingPanel } from "../LoadingPanel/LoadingPanel";

import {
	IntlProvider,
	LocalizationProvider,
	loadMessages,
} from "@progress/kendo-react-intl";
import frMessages from "../../fr.json";
loadMessages(frMessages, "fr-FR");

interface props extends TableDefinition {
	isOnlyTable: Boolean;
}
export interface TableDefinition {
	promiseData?: (options: QueryOptions) => Promise<any>;
	promise?: () => Promise<any>;
	colunsDefinition: ColumnDefinition[];
	onSelectedItemChange?: (item: any[] | null) => void;
	hasSelection?: boolean;
	tableId: string;
	enableFilters?: boolean;
	initialState?: IQueryOptions;
	dataKey?: string;
	saveSate?: boolean;
	title?: string;
}

export interface ColumnDefinition {
	title: string;
	field: string;
	filterable?: boolean;
	orderable?: boolean;
	renderingComponent?:
		| React.ComponentClass<GridCellProps, any>
		| React.FunctionComponent<GridCellProps>;
	filterComponent?:
		| React.ComponentClass<GridFilterCellProps, any>
		| React.FunctionComponent<GridFilterCellProps>;
}

interface state {
	selectedRow: any;
	data: Array<any>;
	total: number;
	options: IQueryOptions;
	sort: SortDescriptor[];
	filter: CompositeFilterDescriptor | undefined;
	pageable: GridPagerSettings;
	loading: Boolean;
	tableUniqId: string;
}

const storageKey = "table_state_@id";

export default class CustomTables extends React.Component<props, state> {
	static defaultProps = {
		hasSelection: true,
		enableFilters: false,
		saveSate: true,
		orderable: true,
	};
	lastSelectedIndex = 0;
	constructor(props: props) {
		super(props);

		let initialState = this.props.initialState;
		if (props.saveSate) {
			const state = localStorage.getItem(
				storageKey.replace("@id", this.props.tableId)
			);
			if (state) {
				initialState = Object.assign(JSON.parse(state), initialState);
			}
		}
		if (
			!initialState ||
			!initialState.pageNumber ||
			!initialState.pageSize ||
			!initialState.search
		) {
			initialState = Object.assign(
				{ pageNumber: 0, pageSize: 10, search: "" },
				initialState
			);
		}

		this.state = {
			data: [],
			total: 0,
			loading: false,
			selectedRow: null,
			sort:
				initialState && initialState.orderByField && initialState.orderDirection
					? [
							{
								dir:
									initialState.orderDirection.toUpperCase() === "DESC"
										? "desc"
										: "asc",
								field: initialState.orderByField,
							},
					  ]
					: [],
			options: initialState,
			filter:
				initialState && initialState.filters
					? {
							logic: "and",
							filters: initialState.filters.map((f) => ({
								field: f.field || "",
								value: f.values,
								operator: operatorIn,
							})),
					  }
					: undefined,
			pageable: {
				info: true,
				buttonCount: 5,
				pageSizes: true,
				previousNext: true,
				type: "numeric",
			},
			tableUniqId: _.uniqueId("table_"),
		};
	}

	async componentDidMount() {
		await this.loadDatas();
	}

	loadDatas = async () => {
		let datas;
		localStorage.setItem(
			storageKey.replace("@id", this.props.tableId),
			JSON.stringify(this.state.options)
		);
		this.setState({ loading: true });
		if (this.props.promise) {
			const temp = await this.props.promise();
			datas = {
				data: temp,
				totalCount: temp.length,
				page: 0,
			};
		} else if (this.props.promiseData) {
			try {
				datas = await this.props.promiseData(
					new QueryOptions(this.state.options)
				);
			} catch (err) {
				console.log(this.state.options);
				datas = [];
			}
		} else {
			return;
		}
		const options = this.state.options;
		let data = [];
		let total = 0;
		let selectedData = null;
		if (datas) {
			data = datas.data;
			options.pageNumber = datas.page;
			total = datas.totalCount;
		} else {
			options.pageNumber = 0;
		}

		options.selectedRow = null;

		this.setState({
			...this.state,
			data: data,
			total: total,
			options: options,
			loading: false,
		});

		this.props.onSelectedItemChange &&
			this.props.onSelectedItemChange(selectedData ? [selectedData] : null);
	};

	dataRecieved = (items: []) => {
		this.setState({
			...this.state,
			data: items,
		});
	};

	selectionChange = (event: GridSelectionChangeEvent) => {
		if (this.props.hasSelection) {
			const data = this.state.data.map((item: any) => {
				if (item.index === event.dataItem.index) {
					item.selected = !event.dataItem.selected;
					if (item.selected && this.props.dataKey) {
						const options = this.state.options;
						options.selectedRow = _.get(
							item.selected,
							this.props.dataKey
						).toString();
						this.setState({ options: options });
					}
				}
				return item;
			});
			this.setState({ data: data });
		}
	};

	rowClick = (event: GridRowClickEvent) => {
		if (this.props.hasSelection) {
			let last = this.lastSelectedIndex;
			const data = [...this.state.data];
			const current = data.findIndex((dataItem) => dataItem === event.dataItem);

			if (!event.nativeEvent.shiftKey) {
				this.lastSelectedIndex = last = current;
			}

			if (!event.nativeEvent.ctrlKey) {
				data.forEach((item) => (item.selected = false));
			}
			const select = !event.dataItem.selected;
			for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
				data[i].selected = select;
			}
			const options = this.state.options;
			if (this.props.dataKey) {
				options.selectedRow = _.get(
					event.dataItem,
					this.props.dataKey
				).toString();
			}

			this.setState({
				options: options,
				data: data,
				selectedRow: [event.dataItem],
			});
			localStorage.setItem(
				storageKey.replace("@id", this.props.tableId),
				JSON.stringify(this.state.options)
			);
			this.props.onSelectedItemChange &&
				this.props.onSelectedItemChange([event.dataItem]);
		}
	};

	pageChange = async (event: GridPageChangeEvent) => {
		const pageNumber = event.page.skip / this.state.options.pageSize;
		const pageSize = event.page.take;
		const options = this.state.options;
		options.pageSize = pageSize;
		options.pageNumber = pageNumber;
		this.setState({
			options: options,
		});

		await this.loadDatas();
	};

	searchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const options = this.state.options;
		const target = event.target;
		options.search = target.value;
		options.pageNumber = 0;
		options.cultureInfo = navigator.language;
		this.setState({ options: options });
		await this.loadDatas();
	};

	sortChange = async (event: GridSortChangeEvent) => {
		const options = this.state.options;
		if (event.sort.length === 1) {
			const sort = event.sort[0];
			options.orderByField = sort.field;
			options.orderDirection = sort.dir;
			this.setState({ sort: [sort] });
		} else if (event.sort.length === 0) {
			options.orderByField = null;
			options.orderDirection = null;
			this.setState({ sort: [] });
		}

		this.setState({ options: options });
		await this.loadDatas();
	};

	generateColumns() {
		const cols = [];
		for (const col of this.props.colunsDefinition) {
			const width = Number(
				localStorage.getItem(`${this.props.tableId}.${col.field}`)
			);
			cols.push(
				<Column
					key={this.props.tableId + col.field}
					field={col.field}
					title={col.title}
					filterable={col.filterable || false}
					cell={col.renderingComponent}
					filterCell={col.filterComponent}
					sortable={col.orderable}
					width={width !== 0 ? width : undefined}
				/>
			);
		}
		return cols;
	}

	filterChange = async (event: GridFilterChangeEvent) => {
		const options = this.state.options;
		const filters = [];
		if (event.filter) {
			for (const f of event.filter.filters) {
				if (!isCompositeFilterDescriptor(f)) {
					const field = f.field as string;
					const value = f.value.map((v: any) => v.toString());
					filters.push({ field: field, values: value });
				}
			}
		}
		this.setState({ filter: event.filter });
		options.filters = filters;
		options.pageNumber = 0;
		this.setState({ options: options });

		await this.loadDatas();
	};

	columnWidthChange = async (event: GridColumnResizeEvent) => {
		if (event.end) {
			const localStorageKey = `${this.props.tableId}.${
				event.columns[event.index].field
			}`;
			localStorage.setItem(localStorageKey, event.newWidth.toString());
		}
	};

	render() {
		return (
			<div
				className={
					"divTable" + (this.props.isOnlyTable ? " divTable-max-height" : "")
				}
				id={this.state.tableUniqId}
			>
				{this.props.title && (
					<span className="tableTitle">{this.props.title}</span>
				)}
				<div className="searchDiv">
					<TextField
						label="Recherche"
						variant="filled"
						value={this.state.options.search}
						className={
							"searchField" +
							(this.state.options.search && this.state.options.search.length > 0
								? " searchField-text-inside"
								: "")
						}
						onChange={this.searchChange}
					/>
				</div>
				{this.state.loading && (
					<LoadingPanel id={this.state.tableUniqId + " .k-grid-content"} />
				)}
				<LocalizationProvider language="fr-FR">
					<IntlProvider locale="fr">
						<Grid
							style={{
								height: this.props.isOnlyTable ? "calc(100% - 49px)" : "auto",
							}}
							filterable={this.props.enableFilters}
							sortable={true}
							pageable={this.state.pageable}
							key={this.props.dataKey}
							sort={this.state.sort}
							skip={this.state.options.pageNumber * this.state.options.pageSize}
							selectedField="selected"
							onSelectionChange={this.selectionChange}
							onRowClick={this.rowClick}
							onPageChange={this.pageChange}
							pageSize={this.state.options.pageSize}
							total={this.state.total}
							data={this.state.data}
							onFilterChange={this.filterChange}
							onSortChange={this.sortChange}
							filter={this.state.filter}
							resizable
							onColumnResize={this.columnWidthChange}
						>
							{this.generateColumns()}
						</Grid>
					</IntlProvider>
				</LocalizationProvider>
			</div>
		);
	}
}
