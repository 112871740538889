import React from "react";
import "./Request.scss";
import { navigationStore } from "../../stores/NavigationStore";
import { headerStore } from "../../stores/HeaderStore";
import { footerStore } from "../../stores/FooterStore";
import { backgroundStore } from "../../stores/BackgroundStore";
import { AvailableImgs } from "../../Models/AvailableImgs";
import RequestForm from "./Form/RequestForm";

type props = {
	onRequestClose?: () => void;
};
type state = {};

export default class Request extends React.Component<props, state> {
	constructor(props: props) {
		super(props);

		this.state = {};

		navigationStore.setState({
			contractId: null,
			siteId: null,
			callId: null,
			objectId: null,
			title: "Générer un appel",
		});

		headerStore.setState({
			leftImgSrc: AvailableImgs.TwentyFourHDepanage,
			rightImgSrc: AvailableImgs.Logo,
			text: "Nous saurons vous convaincre par notre compétence",
		});

		footerStore.setState({
			show: true,
			leftText: "7/7 24h/24h Maintenance et dépannages",
			centerText: "Gestion et maintenance, facility management technique",
			rightText: "www.elco.lu",
		});

		backgroundStore.setState({
			img: AvailableImgs.Fond2Techniciens,
		});
	}

	render() {
		return <RequestForm onRequestClose={this.props.onRequestClose} />;
	}
}
