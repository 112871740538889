import React, { Fragment} from 'react';
import {withAITracking} from '@microsoft/applicationinsights-react-js';
import {ai} from '../../Services/telemetryService';
import {withRouter, RouteComponentProps} from 'react-router-dom';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
interface props extends RouteComponentProps<any>{
    instrumentationKey: string,
    after: () => void
};
type state= {
    initialized: boolean
}
class TelemetryProvider extends React.Component<props, state> {
    static defaultProps = {
        after: () => {}
    }

    constructor(props: props){
        super(props);
        this.state = {
            initialized: false
        };
    }


    componentDidMount() {
        const {history} = this.props;
        const {initialized} = this.state;
        const AppInsightsInstrumentationKey = this.props.instrumentationKey;
        if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
            ai.initialize(AppInsightsInstrumentationKey, history);
            this.setState({initialized: true});
        }

        this.props.after();
    }

    render() {
        const {children} = this.props;
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider, undefined, "rooter-content"));