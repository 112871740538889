import React from "react";
import "./Prevents.scss";
import {
	Link as Aref,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
	CallsClient,
	FollowUpDto,
	StatusCallDto,
} from "../../../Services/elco.API.Swagger";
import InputIcon from "@material-ui/icons/Input";
import { navigationStore } from "../../../stores/NavigationStore";
import { headerStore } from "../../../stores/HeaderStore";
import { AvailableImgs } from "../../../Models/AvailableImgs";
import { footerStore } from "../../../stores/FooterStore";
import { backgroundStore } from "../../../stores/BackgroundStore";
import PageWithTablesTemplate from "../../../Components/PageWithTablesTemplate/PageWithTablesTemplate";
import DropdownFilterCell from "../../../Components/DropdownFilterCell/DropdownFilterCell";
import PreventsReports from "./Documents/Documents";

type props = {
	contract: string;
	site: string;
};

type state = {
	contractId: string;
	siteId: string;
	callId: string;
	selectedRow: any;
	dialogOpen: boolean;
	followUps: FollowUpDto[];
	status: StatusCallDto[];
	callClient: CallsClient;
};

export default class Prevents extends React.Component<
	RouteComponentProps<props>,
	state
> {
	constructor(props: RouteComponentProps<props>) {
		super(props);

		this.state = {
			contractId: props.match.params.contract,
			siteId: props.match.params.site,
			callId: "",
			selectedRow: null,
			dialogOpen: false,
			followUps: [],
			status: [],
			callClient: new CallsClient(),
		};

		this.state.callClient
			.getAllFollowUp()
			.then((response) => this.setState({ followUps: response || [] }));
		this.state.callClient
			.getAllStatus()
			.then((response) => this.setState({ status: response || [] }));

		navigationStore.setState({
			contractId: decodeURIComponent(this.state.contractId),
			siteId: decodeURIComponent(this.state.siteId),
			callId: null,
			title: "Rapports de maintenance préventive",
		});

		headerStore.setState({
			leftImgSrc: AvailableImgs.TwentyFourHDepanage,
			rightImgSrc: AvailableImgs.Logo,
			text: "Nous saurons vous convaincre par notre compétence",
		});

		footerStore.setState({
			show: true,
			leftText: null,
			centerText:
				"Prestation par du personnel hautement qualifié: électricien, mecatronicien, mécanicien, soudeur, tuyauteur, monteur-voltigeur",
			rightText: "www.elco.lu",
		});

		backgroundStore.setState({
			img: AvailableImgs.FondCollecteur1,
		});
	}

	getFollowUpText(dataItem: any) {
		const item = this.state.followUps.find(
			(f) => f.followup === dataItem.followUp
		);
		if (item) {
			return item.description;
		}
	}

	render() {
		return (
			<React.Fragment>
				<PageWithTablesTemplate
					className="prevents"
					buttonCenter={{
						isEnableWhenRowSelected: false,
						label: "Retour",
						url: "/contracts/" + this.state.contractId + "/sites",
					}}
					buttonBottom={{
						isEnableWhenRowSelected: false,
						label: "Accueil",
						url: "/",
					}}
					tables={[
						{
							tableId: `preventTable__${this.state.contractId}_${this.state.siteId}`,
							enableFilters: true,
							promiseData: (options) =>
								this.state.callClient.getPreventCall(
									this.state.contractId,
									this.state.siteId,
									options
								),
							colunsDefinition: [
								{ title: "Campagne", field: "visitDescription" },
								{ title: "Inst. tech.", field: "objectId" },
								{ title: "Sujet", field: "callSubject" },
								{
									title: "Statut Appel",
									field: "status",
									filterComponent: (props) => (
										<DropdownFilterCell
											items={this.state.status}
											textField="id"
											dataItemKey="id"
											placeholder="(Aucun)"
											{...props}
										/>
									),
									filterable: true,
								},
								{
									title: "Suite à donner",
									field: "followUp",
									filterComponent: (props) => (
										<DropdownFilterCell
											items={this.state.followUps}
											textField="description"
											dataItemKey="followup"
											placeholder="(Aucune)"
											{...props}
										/>
									),
									filterable: true,
									renderingComponent: (props) => (
										<td>{this.getFollowUpText(props.dataItem)}</td>
									),
								},
								{ title: "Motif de suite", field: "followUpReason" },
								{ title: "N° d'appel", field: "callId" },
								{
									title: "Rapports",
									field: "hasDocuments",
									orderable: false,
									renderingComponent: (props) => (
										<td>
											{props.dataItem.hasDocuments && (
												<Aref
													href="#"
													onClick={this.handleOpenDialog(
														encodeURIComponent(props.dataItem.callId)
													)}
												>
													<InputIcon />
												</Aref>
											)}
										</td>
									),
								},
							],
							onSelectedItemChange: (item) => {
								this.setState({ selectedRow: item });
							},
							dataKey: "objectId",
						},
					]}
				/>
				<Dialog
					onClose={this.handleCloseDialog.bind(this)}
					aria-labelledby="customized-dialog-title"
					open={this.state.dialogOpen}
					fullWidth={true}
					maxWidth={"md"}
				>
					<DialogTitle id="customized-dialog-title">
						Liste des documents préventifs
					</DialogTitle>
					<DialogContent dividers>
						<PreventsReports
							contract={this.state.contractId}
							site={this.state.siteId}
							call={this.state.callId}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							autoFocus
							onClick={this.handleCloseDialog.bind(this)}
							color="primary"
						>
							Fermer
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}

	private handleOpenDialog = (itemId: string): any => {
		return () => {
			this.setState({
				callId: itemId,
				dialogOpen: true,
			});
		};
	};
	private handleCloseDialog = () => {
		this.setState({
			dialogOpen: false,
		});
	};
}
