import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import './TopBar.scss';
import { IHeaderStoreState, headerStore } from "../../stores/HeaderStore";
import { StoreEvent, StoreEventType } from "react-stores";

type props = {
    className: string
}
type state = {
    headerStore: IHeaderStoreState | null;
}
export default class TopBar extends React.Component<props, state> {
    static defaultProps = {
        className: ''
    }

    constructor(props: props) {
        super(props);

        this.state = {
            headerStore: null
        }
    }

    private storeEvent: StoreEvent<IHeaderStoreState> | null = null;

    componentWillUnmount() {
        // Remove store state event binder
        if (this.storeEvent) {
            this.storeEvent.remove();
        }
    }

    componentDidMount() {
        this.storeEvent = headerStore.on(
            StoreEventType.All,
            (storeState: IHeaderStoreState, prevState: IHeaderStoreState, type: StoreEventType) => {
                this.setState({ headerStore: storeState });
            },
        );
    }

    render() {
        const { leftImgSrc, rightImgSrc, text } = this.state.headerStore || { leftImgSrc: '', rightImgSrc: '', text: '' };
        return (
            <AppBar position='relative' className={this.props.className}>
                <Toolbar className="top-bar">
                    <div className={"logo " + leftImgSrc} ></div>
                    <div className='title'>
                        {text}
                    </div>
                    <div className={"banner " + rightImgSrc} ></div>
                </Toolbar>
            </AppBar>
        )
    }
}