// myStore.ts
import { Store } from 'react-stores';
 
export interface IFooterStoreState {
  rightText: string | null,
  centerText: string | null,
  leftText: string | null,
  show: boolean
}
 
export const footerStore = new Store<IFooterStoreState>({
  rightText: null,
  centerText: null,
  leftText: null,
  show: false
});