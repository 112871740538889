/* tslint:disable */
/* eslint-disable */
import { ElcoBaseClient } from "./ElcoBaseClient";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { RequestDto } from "./elco.API.Swagger";
import { UploadFileInfo } from "@progress/kendo-react-upload/dist/npm/interfaces/UploadFileInfo";

export class ElcoRequestClient extends ElcoBaseClient {
	private instance: AxiosInstance;
	private baseUrl: string;

	constructor(baseUrl?: string, instance?: AxiosInstance) {
		super();
		this.instance = instance ? instance : axios.create();
		this.baseUrl = baseUrl ? baseUrl : this.getBaseUrl("");
	}

	async uploadFiles(files: UploadFileInfo[]): Promise<string> {
		let url = this.baseUrl + "/api/Request/Upload";
		let formData = new FormData();
		for (var i = 0; i < files.length; i++) {
			const fileInfo = files[i];
			if (fileInfo.getRawFile) {
				const file = fileInfo.getRawFile();
				formData.append("files[" + i + "]", file);
			}
		}

		const _response = await this.instance.post(url, formData, {
			headers: {
				Accept: "application/json",
				"Content-Type": "multipart/form-data",
			},
		});
		return Promise.resolve(_response.data as string);
	}
}
