/* eslint-disable eqeqeq */
import React from "react";

import {
	MultiSelect,
	MultiSelectChangeEvent,
	ListItemProps,
} from "@progress/kendo-react-dropdowns";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import _ from "lodash";
import { Checkbox } from "@progress/kendo-react-inputs";
import "./DropDownFilterCell.scss";

interface props extends GridFilterCellProps {
	items: any;
	placeholder?: string;
	textField: string;
	dataItemKey: string;
}
interface state {}
export const operatorIn = (current: any, datas: []) =>
	_.find(datas, (d) => d === current) != null;
export default class extends React.Component<props, state> {
	itemRender = (
		li: React.ReactElement<HTMLLIElement>,
		itemProps: ListItemProps
	): React.ReactNode => {
		const itemChildren = (
			<span>
				<Checkbox checked={itemProps.selected} onChange={() => {}} />
				&nbsp;{li.props.children}
			</span>
		);
		return React.cloneElement(li, li.props, itemChildren);
	};

	render() {
		const value =
			this.props.value &&
			this.props.items.filter(
				(i: any) =>
					_.find(
						this.props.value,
						(v: any) => v == _.get(i, this.props.dataItemKey)
					) != null
			);
		const selected = value.length;
		return (
			<div className="k-filtercell">
				<MultiSelect
					className="customdropdownfilter"
					data={this.props.items}
					onChange={this.onChange}
					value={selected > 0 ? value : []}
					textField={this.props.textField}
					itemRender={this.itemRender}
					dataItemKey={this.props.dataItemKey}
					placeholder={this.props.placeholder}
					filterable={false}
					tags={
						selected > 0
							? [
									{
										text: `${selected} filtre${selected > 1 ? "s" : ""}`,
										data: [...value],
									},
							  ]
							: []
					}
					style={{ height: "1dp" }}
				/>
			</div>
		);
	}

	hasValue = (value: any) => Boolean(value);

	onChange = (event: MultiSelectChangeEvent) => {
		const hasValue = this.hasValue(event.target.value);
		this.props.onChange({
			value: hasValue
				? event.target.value.map((v) => _.get(v, this.props.dataItemKey))
				: null,
			operator: hasValue ? operatorIn : "",
			syntheticEvent: event.syntheticEvent,
		});
	};
}
