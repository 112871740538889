import React from "react";

import ReactDOM from "react-dom";

interface props {
    id: string
}
export class LoadingPanel extends React.Component<props, {}> {
    render() {
        const loadingPanel = (
            <div className="k-loading-mask">
                <span className="k-loading-text">Chargement en cours...</span>
                <div className="k-loading-image"></div>
                <div className="k-loading-color"></div>
            </div>
        );

        const gridContent = document && document.querySelector('#' + this.props.id);
        return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
    }
}