import { AxiosInstance } from "axios";
import _ from "lodash";
import { authContext } from "../Models/adalConfig";

export class ElcoBaseClient {
	protected getBaseUrl(url?: string) {
		// eslint-disable-next-line
		(<AxiosInstance>_.get(this, "instance")).interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error && error.response && 401 === error.response.status) {
					// Et qu'on est pas dans la page de login actuellement
					//Deconnecter le user proprement
					authContext.logOut();
				} else {
					return Promise.reject(error);
				}
			}
		);

		const apiUrl = process.env.REACT_APP_BASE_URL;
		return apiUrl ? apiUrl : "";
	}
}
