import React from "react";
import "./Contracts.scss";
import {
	Link,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from "@material-ui/core";
import { ContractClient } from "../../Services/elco.API.Swagger";
import InputIcon from "@material-ui/icons/Input";
import { navigationStore } from "../../stores/NavigationStore";
import { headerStore } from "../../stores/HeaderStore";
import { AvailableImgs } from "../../Models/AvailableImgs";
import { footerStore } from "../../stores/FooterStore";
import { backgroundStore } from "../../stores/BackgroundStore";
import PageWithTablesTemplate from "../../Components/PageWithTablesTemplate/PageWithTablesTemplate";
import ContractsReports from "./Documents/ContractsReports";
import { ContractDocumentType } from "./Documents/DocumentType";

type props = {};
type state = {
	selectedRow: any;
	documentDialogOpen: boolean;
	selectedContract: any;
	docType: ContractDocumentType;
};

export default class Contracts extends React.Component<props, state> {
	constructor(props: props) {
		super(props);

		this.state = {
			selectedRow: {},
			documentDialogOpen: false,
			selectedContract: null,
			docType: ContractDocumentType.report,
		};

		navigationStore.setState({
			contractId: null,
			siteId: null,
			callId: null,
			objectId: null,
			title: "Liste des contrats",
		});

		headerStore.setState({
			leftImgSrc: AvailableImgs.TwentyFourHDepanage,
			rightImgSrc: AvailableImgs.Logo,
			text: "Nous saurons vous convaincre par notre compétence",
		});

		footerStore.setState({
			show: true,
			leftText: "7/7 24h/24h Maintenance et dépannages",
			centerText: "Gestion et maintenance, facility management technique",
			rightText: "www.elco.lu",
		});

		backgroundStore.setState({
			img: AvailableImgs.Fond2Techniciens,
		});
	}

	render() {
		return (
			<React.Fragment>
				<PageWithTablesTemplate
					className="contracts"
					buttonTop={{
						isEnableWhenRowSelected: true,
						label: "Liste des sites",
						url:
							"contracts/" +
							encodeURIComponent(
								this.state.selectedRow && this.state.selectedRow.id
							) +
							"/sites",
					}}
					buttonBottom={{
						isEnableWhenRowSelected: false,
						label: "Accueil",
						url: "/",
					}}
					tables={[
						{
							tableId: "contractTable",
							promiseData: (options) => new ContractClient().get(options),
							colunsDefinition: [
								{ title: "Contrat", field: "id" },
								{ title: "Nom du contrat", field: "name" },
								{ title: "Compte client", field: "customerId" },
								{
									title: "Documents contractuels",
									orderable: false,
									field: "hasDocuments",
									renderingComponent: (props) => (
										<td>
											{props.dataItem.hasDocuments && (
												<Link
													href="#"
													onClick={this.handleOpenDialog(
														encodeURIComponent(props.dataItem.id),
														ContractDocumentType.report
													)}
												>
													<InputIcon />
												</Link>
											)}
										</td>
									),
								},
								{
									title: "Documents financiers",
									orderable: false,
									field: "hasInvoices",
									renderingComponent: (props) => (
										<td>
											{props.dataItem.hasInvoices && (
												<Link
													href="#"
													onClick={this.handleOpenDialog(
														encodeURIComponent(props.dataItem.id),
														ContractDocumentType.invoice
													)}
												>
													<InputIcon />
												</Link>
											)}
										</td>
									),
								},
							],
							onSelectedItemChange: (item) => {
								this.setState({ selectedRow: item });
							},
							dataKey: "id",
						},
					]}
				/>

				<Dialog
					onClose={this.handleCloseDialog.bind(this)}
					aria-labelledby="customized-dialog-title"
					open={this.state.documentDialogOpen}
					fullWidth={true}
					maxWidth={"md"}
				>
					<DialogTitle id="customized-dialog-title">
						{this.state.docType === ContractDocumentType.report
							? "Liste des documents"
							: "Liste des documents financiers"}
					</DialogTitle>
					<DialogContent dividers>
						<ContractsReports
							contract={this.state.selectedContract}
							docType={this.state.docType}
						/>
					</DialogContent>
					<DialogActions>
						<Button
							autoFocus
							onClick={this.handleCloseDialog.bind(this)}
							color="primary"
						>
							Fermer
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}

	private handleOpenDialog = (
		itemId: any,
		docType: ContractDocumentType
	): any => {
		return () => {
			this.setState({
				selectedContract: itemId,
				documentDialogOpen: true,
				docType: docType,
			});
		};
	};

	private handleCloseDialog = () => {
		this.setState({
			documentDialogOpen: false,
		});
	};
}
