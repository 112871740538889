// myStore.ts
import { Store } from 'react-stores';
import { AvailableImgs } from '../Models/AvailableImgs';
 
export interface IHeaderStoreState {
  rightImgSrc: AvailableImgs,
  leftImgSrc: AvailableImgs,
  text: string
}
 
export const headerStore = new Store<IHeaderStoreState>({
  rightImgSrc: AvailableImgs.Logo,
  leftImgSrc: AvailableImgs.TwentyFourHDepanage,
  text: 'Nous saurons vous convraincre par notre compétence'
});

