import React from 'react';
import './RequestFrom.scss';
import {
  ContractClient,
  ContractDto,
  SitesClient,
  SiteDto,
  ObjectsClient,
  ObjectDto,
} from '../../../Services/elco.API.Swagger';
import { adalGraphFetch, getGivenName, getUserName } from '../../../Models/adalConfig';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { MenuItem, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import MuiAlert from '@material-ui/lab/Alert';
import { Upload, UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from '@progress/kendo-react-upload';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { ElcoRequestClient } from '../../../Services/elco.requestClient';
import { RequestDto, IRequestDto, RequestClient } from '../../../Services/elco.API.Swagger';

type props = {
  onRequestClose?: () => void;
};
type state = {
  user: any;
  requester: string;
  requesterError: boolean;
  contractClient: ContractClient;
  contracts: ContractDto[];
  selectedContract: ContractDto;
  selectedContractId: string;
  contractError: boolean;
  siteClient: SitesClient;
  sites: SiteDto[];
  selectedSite: SiteDto;
  selectedSiteId: string;
  siteError: boolean;
  objectClient: ObjectsClient;
  objects: ObjectDto[];
  selectedObject: ObjectDto;
  selectedObjectId: string;
  files: UploadFileInfo[];
  phone: string;
  phoneError: boolean;
  mail: string;
  mailError: boolean;
  reason: string;
  reasonError: boolean;
  company: string;
  isFormValid: boolean;
  isSent: boolean;
  openSnackBar: boolean;
  inProgress: boolean;
};

interface Profile {
  displayName?: string;
  companyName?: string;
  mobilePhone?: string;
  mail?: string;
}

export default class RequestForm extends React.Component<props, state> {
  constructor(props: props) {
    super(props);
    this.state = {
      user: null,
      requester: '',
      requesterError: false,
      contractClient: new ContractClient(),
      contracts: [],
      selectedContract: {} as ContractDto,
      selectedContractId: '',
      contractError: false,
      siteClient: new SitesClient(),
      sites: [],
      selectedSite: {} as SiteDto,
      selectedSiteId: '',
      siteError: false,
      objectClient: new ObjectsClient(),
      objects: [],
      selectedObject: {} as ObjectDto,
      selectedObjectId: '',
      files: [],
      phone: '',
      phoneError: false,
      mail: getUserName(),
      mailError: false,
      reason: '',
      reasonError: false,
      company: '',
      isFormValid: true,
      isSent: false,
      openSnackBar: false,
      inProgress: false,
    };
  }

  async componentDidMount() {
    try {
      const oContracts = await this.state.contractClient.getAll();
      const profile: Profile = await adalGraphFetch('me?$select=companyName,displayName,mobilePhone,mail');

      !profile.displayName && (profile.displayName = getGivenName());

      this.setState({
        contracts: oContracts ? oContracts : [],
        user: profile,
        requester: profile.displayName || '',
        company: profile.companyName || '',
        phone: profile.mobilePhone || '',
      });
    } catch (error) {
      console.error(`error while getting connected user ${error}`);
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.inProgress ? (
          <div>
            {this.state.openSnackBar ? (
              this.state.isSent ? (
                <Alert severity="success" onClose={this.handelSnackClose}>
                  <AlertTitle>Succès</AlertTitle>
                  Votre appel a été transmis au Helpdesk ELCO qui fera en sorte de le traiter le plus rapidement
                  possible
                </Alert>
              ) : (
                <Alert severity="error" onClose={this.handelSnackClose}>
                  <AlertTitle>Erreur</AlertTitle>
                  Votre appel n'a pas été transmis correctement
                </Alert>
              )
            ) : (
              <CircularProgress style={{ marginLeft: '45%' }} />
            )}
          </div>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MuiAlert severity="warning" elevation={6} variant="filled">
                Pour une intervention d’urgence ou pour joindre le service d’astreinte veuillez appeler le +352 40 11
                498
              </MuiAlert>
            </Grid>
            {/* Contrat */}
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Paper elevation={3}>
                <TextField
                  id="Contract"
                  name="Contract"
                  select
                  required
                  disabled={this.state.contracts.length <= 0}
                  error={this.state.contractError}
                  helperText={this.state.contractError ? 'Saisie incorrecte' : ''}
                  label="Sélectionner un contrat"
                  value={this.state.selectedContractId}
                  onChange={this.handelContractChange.bind(this)}
                  variant="outlined"
                  style={{ width: '100%' }}
                >
                  {this.state.contracts.map(c => (
                    <MenuItem key={c.id ? c.id : -1} value={c.id ? c.id : -1}>
                      {c.id} - {c.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Paper>
            </Grid>
            <Grid item xs={1} />
            {/* Site */}
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Paper elevation={3}>
                <TextField
                  id="Site"
                  name="Site"
                  select
                  required
                  disabled={this.state.sites.length <= 0}
                  error={this.state.siteError}
                  helperText={this.state.siteError ? 'Saisie incorrecte' : ''}
                  label="Sélectionner un site"
                  value={this.state.selectedSiteId}
                  onChange={this.handelSiteChange.bind(this)}
                  variant="outlined"
                  style={{ width: '100%' }}
                >
                  {this.state.sites.map(s => (
                    <MenuItem key={s.id ? s.id : -1} value={s.id ? s.id : -1}>
                      {s.id} - {s.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Paper>
            </Grid>
            <Grid item xs={1} />
            {/* Client */}
            <Grid item xs={6}>
              <Paper elevation={3}>
                <TextField
                  label="Nr Client"
                  value={this.state.selectedContract?.customerId || ''}
                  InputProps={{ readOnly: true }}
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </Paper>
            </Grid>
            {/* User */}
            <Grid item xs={6}>
              <Paper elevation={3}>
                <TextField
                  label="Utilisateur"
                  value={this.state.user ? this.state.user.displayName : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </Paper>
            </Grid>
            {/* Requester */}
            <Grid item xs={6}>
              <Paper elevation={3}>
                <TextField
                  label="Nom, Prénom demandeur"
                  value={this.state.requester}
                  error={this.state.requesterError}
                  helperText={this.state.requesterError ? 'Saisie incorrecte' : ''}
                  onChange={this.handelRequesterChange.bind(this)}
                  required
                  InputProps={{}}
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </Paper>
            </Grid>
            {/* Company */}
            <Grid item xs={6}>
              <Paper elevation={3}>
                <TextField
                  label="Société"
                  value={this.state.company}
                  onChange={this.handelCompanyChange.bind(this)}
                  InputProps={{}}
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </Paper>
            </Grid>
            {/* Nr Tel */}
            <Grid item xs={6}>
              <Paper elevation={3}>
                <TextField
                  label="Nr Tél"
                  required
                  value={this.state.phone}
                  error={this.state.phoneError}
                  helperText={this.state.phoneError ? 'Saisie incorrecte' : ''}
                  onChange={this.handelPhoneChange.bind(this)}
                  InputProps={{}}
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </Paper>
            </Grid>
            {/* e-Mail */}
            <Grid item xs={6}>
              <Paper elevation={3}>
                <TextField
                  label="Email"
                  type="email"
                  value={this.state.mail}
                  error={this.state.mailError}
                  helperText={this.state.mailError ? 'Saisie incorrecte' : ''}
                  onChange={this.handelMailChange.bind(this)}
                  required
                  InputProps={{}}
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </Paper>
            </Grid>
            {/* Object */}
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Paper elevation={3}>
                <TextField
                  id="Object"
                  name="Object"
                  select
                  label="Installation technique concernée (si connue)"
                  disabled={this.state.objects.length <= 0}
                  value={this.state.selectedObjectId}
                  InputProps={{}}
                  variant="outlined"
                  onChange={this.handelObjectChange.bind(this)}
                  style={{ width: '100%' }}
                >
                  {this.state.objects.map(o => (
                    <MenuItem key={o.objectId ? o.objectId : -1} value={o.objectId ? o.objectId : ''}>
                      {o.objectId} - {o.description}
                    </MenuItem>
                  ))}
                </TextField>
              </Paper>
            </Grid>
            <Grid item xs={1} />
            {/* Reason */}
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Paper elevation={3}>
                <TextField
                  label="Motif de l’appel"
                  required
                  multiline
                  value={this.state.reason}
                  error={this.state.reasonError}
                  helperText={this.state.reasonError ? 'Saisie incorrecte' : ''}
                  onChange={this.handelReasonChange.bind(this)}
                  rows={4}
                  InputProps={{}}
                  variant="outlined"
                  style={{ width: '100%' }}
                />
              </Paper>
            </Grid>
            <Grid item xs={1} />
            {/* File Upload */}
            <Grid item xs={6}>
              <Paper elevation={3}>
                <LocalizationProvider language="fr-FR">
                  <IntlProvider locale="fr">
                    <Upload
                      batch={false}
                      multiple={true}
                      files={this.state.files}
                      onAdd={this.onAdd}
                      onRemove={this.onRemove}
                      autoUpload={false}
                      showActionButtons={false}
                      disabled={this.state.inProgress}
                    />
                  </IntlProvider>
                </LocalizationProvider>
              </Paper>
            </Grid>
            {/* Send */}
            <Grid item xs={6}>
              <Paper elevation={3}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '100%' }}
                  onClick={this.handelSendRequest.bind(this)}
                  disabled={this.state.inProgress}
                >
                  Envoyer
                </Button>
              </Paper>
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }

  handelContractChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const oContract = this.state.contracts.filter(c => c.id === event.target.value)[0];
    if (oContract) {
      const contractId = oContract.id ? oContract.id : '';
      const oSites = await this.state.siteClient.getByContrat(encodeURIComponent(contractId));
      this.setState({
        selectedContract: oContract,
        selectedContractId: contractId,
        selectedSite: {} as SiteDto,
        selectedSiteId: '',
        selectedObject: {} as ObjectDto,
        selectedObjectId: '',
        sites: oSites ? oSites : [],
        objects: [],
      });
    }
  };

  handelSiteChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSite = this.state.sites.filter(s => s.id === event.target.value)[0];
    if (selectedSite) {
      const siteId = selectedSite.id ? selectedSite.id : '';
      const objectsSite = await this.state.objectClient.getObjectsByContratSite(
        encodeURIComponent(this.state.selectedContractId),
        encodeURIComponent(siteId)
      );
      this.setState({
        selectedSite: selectedSite,
        selectedSiteId: siteId,
        selectedObject: {} as ObjectDto,
        selectedObjectId: '',
        objects: objectsSite ? objectsSite : [],
      });
    }
  };

  handelObjectChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedObject = this.state.objects.filter(o => o.objectId === event.target.value)[0];
    const objectId = selectedObject.objectId ? selectedObject.objectId : '';
    if (selectedObject) {
      this.setState({
        selectedObject: selectedObject,
        selectedObjectId: objectId,
      });
    }
  };

  handelSendRequest = async () => {
    if (this.validateInput()) {
      this.setState({
        inProgress: true,
      });
      let folderName: string = '';
      if (this.state.files) {
        folderName = await new ElcoRequestClient().uploadFiles(this.state.files);
      }

      const requestData: IRequestDto = {
        contract: this.state.selectedContract,
        site: this.state.selectedSite,
        object: this.state.selectedObject,
        connectedUserMail: getUserName(),
        requester: this.state.requester,
        company: this.state.company,
        phone: this.state.phone,
        mail: this.state.mail,
        reason: this.state.reason,
        attachmentFolderName: folderName,
      };

      const request = new RequestDto(requestData);

      const isSent = await new RequestClient().sendRequest(request);
      this.setState({
        isSent: isSent,
        openSnackBar: true,
      });
    }
  };

  handelSnackClose = () => {
    if (this.state.isSent && this.props.onRequestClose) {
      this.props.onRequestClose();
    }
    this.setState({
      isSent: false,
      openSnackBar: false,
      inProgress: false,
    });
  };

  handelCompanyChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      company: event.target.value,
    });
  };

  handelPhoneChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      phone: event.target.value,
      phoneError: false,
    });
  };

  handelMailChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      mail: event.target.value,
      mailError: false,
    });
  };

  handelReasonChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      reason: event.target.value,
      reasonError: false,
    });
  };

  handelRequesterChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      requester: event.target.value,
      requesterError: false,
    });
  };

  onAdd = (event: UploadOnAddEvent) => {
    this.setState({
      files: event.newState,
    });
  };

  onRemove = (event: UploadOnRemoveEvent) => {
    this.setState({
      files: event.newState,
    });
  };

  validateInput = (): boolean => {
    const mailValidation = new RegExp(/\S+@\S+\.\S+/).test(this.state.mail);
    const phoneValidation = this.state.phone
      ? true
      : false; /*new RegExp(
			/(^\+[0-9]{3}|^\)|^00[0-9]{3}|)([0-9]{9}$|[0-9\-\s]{10}$)/
		).test(this.state.phone);*/
    const reasonValidation = this.state.reason ? true : false;
    const contractValidation = this.state.selectedContract ? true : false;
    const siteValidation = this.state.selectedSite ? true : false;
    const requesterValidation = this.state.requester ? true : false;

    this.setState({
      contractError: !contractValidation,
      siteError: !siteValidation,
      phoneError: !phoneValidation,
      mailError: !mailValidation,
      reasonError: !reasonValidation,
      requesterError: !requesterValidation,
    });

    return mailValidation && phoneValidation && reasonValidation;
  };
}
