export enum AvailableImgs {
  TwentyFourHDepanage = 'HDepannage',
  Logo = 'Logo',
  FondVehicule = 'fondVehicule',
  FondVehicule2 = "fondVehicule2",
  Fond2Techniciens = 'fond2Techniciens',
  FondTechniciens = "fondTechniciens",
  FondToitVentilation = "fondToitVentilation",
  FondBatiment = "fondBatiment",
  FondCollecteur1 = "fondCollecteur1",
  FondCollecteur2 = "fondCollecteur2",
  FondFroidToiture = "fondFroidToiture"
}