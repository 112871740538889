import React from "react";
import "./Documents.scss";
import { Link } from "@material-ui/core";
import {
	DocumentClient,
	DocumentTypeDto,
} from "../../../../Services/elco.API.Swagger";
import { showFile } from "../../../../Models/ShowFile";
import GetAppIcon from "@material-ui/icons/GetApp";
import { LoadingPanel } from "../../../../Components/LoadingPanel/LoadingPanel";
import _ from "lodash";
import PageWithTablesTemplateOnly from "../../../../Components/PageWithTableTemplateOnly/PageWithTablesTemplateOnly";
import DropdownFilterCell from "../../../../Components/DropdownFilterCell/DropdownFilterCell";

type props = {
	contract: string;
	site: string;
	call: string;
};
type state = {
	selectedRowReports: any;
	selectedRowAttachment: any;
	loading: Boolean;
	documentTypes: DocumentTypeDto[];
};
export default class CorrectsReports extends React.Component<props, state> {
	constructor(props: props) {
		super(props);

		this.state = {
			selectedRowReports: null,
			selectedRowAttachment: null,
			loading: false,
			documentTypes: [],
		};

		new DocumentClient()
			.getAllTypes()
			.then((response) => this.setState({ documentTypes: response || [] }));
	}

	handleDownloadDocument(documentId: number) {
		this.setState({ loading: true });
		new DocumentClient()
			.downloadCorrectifCallDocument(documentId)
			.then((response) => (response ? showFile(response) : null))
			.finally(() => this.setState({ loading: false }));
	}

	getDocumentTypeText(dataItem: any) {
		const item = this.state.documentTypes.find((f) => f.id === dataItem.typeid);
		if (item) {
			return item.name;
		}
	}

	render() {
		const idPage = _.uniqueId("correctifs_documents_");
		return (
			<div id={idPage} style={{ width: "100%" }}>
				{this.state.loading && <LoadingPanel id={idPage} />}
				<PageWithTablesTemplateOnly
					className="corrects-reports"
					tables={[
						{
							tableId: `correctDocumentReportTable__${this.props.contract}_${this.props.site}_${this.props.call}`,
							title: "Rapports",
							enableFilters: true,
							promiseData: (options) =>
								new DocumentClient().correctifCallDocuments(
									this.props.contract,
									this.props.site,
									this.props.call,
									options
								),
							colunsDefinition: [
								{
									title: "Date",
									field: "createddatetime",
									renderingComponent: (props) => (
										<td>
											{props.dataItem.createddatetime.format("DD/MM/YYYY")}
										</td>
									),
								},
								{
									title: "Type de document",
									field: "typeName",
									filterable: true,
									filterComponent: (props) => (
										<DropdownFilterCell
											items={this.state.documentTypes}
											textField="name"
											dataItemKey="name"
											placeholder="(Aucun)"
											{...props}
										/>
									),
								},
								{ title: "Nom du document", field: "name" },
								{
									title: "Télécharger",
									field: "recid",
									orderable: false,
									renderingComponent: (props) => (
										<td>
											<Link
												href="#"
												onClick={() =>
													this.handleDownloadDocument(props.dataItem.recid)
												}
											>
												<GetAppIcon />
											</Link>
										</td>
									),
								},
							],
							onSelectedItemChange: (item) => {
								this.setState({ selectedRowReports: item });
							},
							dataKey: "recid",
						},
					]}
				/>
			</div>
		);
	}
}
